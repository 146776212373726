import React from 'react';
import { Line } from 'components';
import { Badge } from 'antd';

export default function ConsumptionGraph(props) {
  const { data, loading, total, xAccessor, totalText, title, leftLegend, bottomLegend } =
    props;

  return (
    <Badge.Ribbon color="gold" placement="start" text={totalText}>
      <Line
        loading={loading}
        data={data}
        title={title}
        xAccessor={xAccessor || "date"}
        yAccessor="value"
        yValuesMax={total}
        axes={{
          left: {
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 0,
            legend: leftLegend,
            legendOffset: -40,
            legendPosition: 'middle',
          },
          bottom: {
            tickSize: 10,
            tickRotation: 0,
            legend: bottomLegend,
            legendOffset: 55,
            legendPosition: 'middle',
          },
        }}
      />
    </Badge.Ribbon>
  );
}
