import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useComponents from 'hooks/useComponents';

//componets
import { Form, PageHeader, Spin, Button, Tabs, Table as TableUI, InputNumber } from 'antd';
import { Container, FormCard } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Save } from '../components/data-point-create/save';
import { InformacionDataPoint, MaximumAxisDataPoint } from '../components';

//I18n
import { I18n } from 'i18n';

//queries
import { GetDataPointsById } from 'apollo/configuration';

//utils
import { ModulesTemetry, SettingDataPoint } from 'utils';

//extension
const { TabPane } = Tabs;
const { Column } = TableUI;

//statename
const stateName = 'configuration.dataPoints';

export function DataPointForm() {
  const location = useLocation();
  const history = useHistory();
  const { codeId } = queryString.parse(location.search);
  const {
    onChange,
    configuration: { dataPoints },
  } = useComponents();

  const { data: dataPointsById, loading, error } = GetDataPointsById({ codeId });
  const [dataTableThreshold, setDataTableThreshold] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    if (ModulesTemetry.includes(dataPoints.module)) {
      delete dataPoints.setting;

      const dataYMaxs = codeId
        ? dataPointsById.setting
        : SettingDataPoint[dataPoints.module || 'temperature_and_humidity'];

      let dataTable = [];

      Object.keys(dataYMaxs).forEach((value, i) => {
        onChange(`${stateName}.setting.${[value]}.yValuesMax`, dataYMaxs[value].yValuesMax, true);
        onChange(`${stateName}.setting.${[value]}.threshold`, dataYMaxs[value].threshold, true);
        dataTable.push({
          key: i,
          module: value,
          thresholds: Object.values(dataYMaxs[value].threshold),
        });
      });
      setDataTableThreshold(dataTable);
    }

    return () => {
      isMounted = false; // Cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoints.module]);

  const updateMax = (moduleName, index, value, field) => {
    setDataTableThreshold((prevData) =>
      prevData.map((module) =>
        module.module === moduleName
          ? {
              ...module,
              thresholds: module.thresholds.map((threshold, i) => {
                if (i === index) {
                  if (field === 'min') return { ...threshold, min: value };
                  return { ...threshold, max: value };
                }
                return threshold;
              }),
            }
          : module,
      ),
    );
  };

  if (codeId && error) history.push('/error-server');

  return (
    <Container style={{ borderWidth: 2, borderRadius: 0, borderColor: '#d6d6d6' }}>
      <PageHeader
        backIcon={[
          <Button type="primary" icon={<FontAwesomeIcon icon={faArrowLeft} />} size="large" />,
        ]}
        onBack={() => history.push('/configuration/data-points')}
        style={{ color: '#272e3a' }}
        title={
          codeId ? I18n('update_datapoints', true, true) : I18n('create_data_points', true, true)
        }
        extra={[<Save codeId={codeId} dataTableThreshold={dataTableThreshold} />]}
      />
      <>
        {!loading ? (
          <Form layout="vertical">
            <Tabs defaultActiveKey="1" onChange={(key) => console.log(key)} centered>
              <TabPane tab={I18n('basic_data_points_information', true)} key="1">
                <InformacionDataPoint
                  dataPointsById={dataPointsById}
                  dataPoints={dataPoints}
                  onChange={onChange}
                  stateName={stateName}
                />
              </TabPane>
              {ModulesTemetry.includes(codeId ? dataPointsById.module : dataPoints.module) && (
                <>
                  <TabPane tab={I18n('maximum_value_on_the_axisy', true)} key="2">
                    <MaximumAxisDataPoint
                      dataPointsById={dataPointsById}
                      dataPoints={dataPoints}
                      onChange={onChange}
                      stateName={stateName}
                    />
                  </TabPane>
                  <TabPane tab={I18n('configuration_thresholds', true)} key="3">
                    {dataTableThreshold.map(({ module, thresholds }) => {
                      return (
                        <article key={module} style={{ width: '50%' }}>
                          <FormCard
                            key={module}
                            title={`${I18n(`thresholds`, true)} ${I18n(module, true)}`}
                            description={I18n('Configuracion de datos para los umbrales')}
                          >
                            <TableUI
                              key={module}
                              dataSource={thresholds.length > 0 ? thresholds : []}
                              pagination={false}
                              bordered
                              rowKey={(_record, index) => index}
                            >
                              <Column
                                title={I18n('min', true)}
                                dataIndex={'min'}
                                key={'min'}
                                render={(key, _record, index) => {
                                  return (
                                    <InputNumber
                                      key={`${stateName}.setting.${[
                                        module,
                                      ]}.threshold.${index}.min`}
                                      defaultValue={key === 'Infinity' ? '' : key}
                                      placeholder={key === 'Infinity' ? 'Infinity' : `min`}
                                      onChange={(value) => updateMax(module, index, value, `min`)}
                                    />
                                  );
                                }}
                              />
                              <Column
                                title={I18n('max', true)}
                                dataIndex={'max'}
                                key={'max'}
                                render={(key, _record, index) => {
                                  return (
                                    <InputNumber
                                      defaultValue={key === 'Infinity' ? '' : key}
                                      placeholder={key === 'Infinity' ? 'Infinity' : `max`}
                                      onChange={(value) => updateMax(module, index, value, `max`)}
                                    />
                                  );
                                }}
                              />
                              <Column
                                width={80}
                                title={I18n('color', true)}
                                dataIndex={'color'}
                                key={'color'}
                                render={(color) => (
                                  <div
                                    style={{
                                      width: 35,
                                      height: 35,
                                      background: color,
                                      borderRadius: 8,
                                    }}
                                  />
                                )}
                              />
                            </TableUI>
                          </FormCard>
                        </article>
                      );
                    })}
                  </TabPane>
                </>
              )}
            </Tabs>
          </Form>
        ) : (
          <Spin spinning={loading} />
        )}
      </>
    </Container>
  );
}
