import { thresholdsColors } from 'utils';
import { I18n } from 'i18n';

export const commonProperties = {
  height: 500,
  margin: { top: 50, right: 130, bottom: 70, left: 60 },
  animate: true,
};

export const Title = ({ width, height, title }) => {
  const style = { fontWeight: 'bold' };
  return (
    <text x={width / 3} y={-10} style={style}>
      {title}
    </text>
  );
};

export const RefsLayer = (props) => {
  return (
    <g>
      {Object.values(props.thresholds).map(({ max, min, color }) => {
        if (max === 'Infinity') {
          if (min > props.yValuesMax) {
            max = min;
          } else {
            max = props.yValuesMax;
          }
        }
        return (
          <rect
            y={props.yScale(max)}
            width={props.width - 190}
            height={props.yScale(min) - props.yScale(max)}
            fill={thresholdsColors[color]}
          />
        );
      })}
    </g>
  );
};

export const legends = [
  {
    anchor: 'top-right',
    direction: 'column',
    justify: false,
    translateX: 100,
    translateY: 0,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 12,
    scrollY: true,
    symbolShape: 'circle',
    symbolBorderColor: 'rgba(0, 0, 0, .43)',
    effects: [
      {
        on: 'hover',
        style: {
          itemBackground: 'rgba(0, 0, 0, .03)',
          itemOpacity: 1,
        },
      },
    ],
  },
];

export function findByYValueMax(thresholds, yValues, yValuesMax = 0) {
  let yValue = Math.max(...yValues),
    yMaxValue = 'auto';
  Object.values(thresholds).forEach(({ max, min }) => {
    if (max === 'Infinity') {
      if (yValuesMax > min && yValuesMax > yValue) {
        return (yMaxValue = yValuesMax);
      } else {
        if (min > yValue) {
          return (yMaxValue = min + 10);
        } else {
          return (yMaxValue = yValue + 10);
        }
      }
    }
  });
  return yMaxValue;
}

export function rawDataReducer(responseData, xAccessor, yAccessor, tickFormat) {
  let rawData = [],
    yValues = [];
  responseData.forEach(({ text, color, data }) => {
    let memo = [];
    data.forEach((rows) => {
      if (!yValues.includes(rows[yAccessor]) && rows[yAccessor] !== null)
        yValues.push(rows[yAccessor]);
      let xValue =
        typeof tickFormat === 'undefined'
          ? rows[xAccessor]
          : I18n(tickFormat[rows[xAccessor]], true);
      memo.push({ x: xValue, y: rows[yAccessor] });
    });
    rawData.push({ id: I18n(text, true), color, data: memo });
  });
  return { rawData, yValues };
}
