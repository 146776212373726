import React, { useState, useEffect } from 'react';
import { Line } from 'components';
import { I18n } from 'i18n';
import { weekArray } from 'utils';
import { useSelector } from 'react-redux';

export default function GraphicLog(props) {
  const [threshold, setThreshold] = useState({});
  const { data, loading } = props;
  const { dataPoints } = useSelector(({ customers: { data } }) => data);
  const {
    environment: {
      maxandmin: {
        historicLog: { block },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (block) {
      const data = dataPoints.find((key) => {
        return key.location === block;
      });
      setThreshold(data.threshold);
    }
  }, [block, dataPoints]);

  return (
    <Line
      title={I18n('max_and_min_temperature', true, true)}
      data={data}
      loading={loading}
      thresholds={threshold}
      xAccessor="day"
      yAccessor="value"
      colors={{ datum: 'color' }}
      axes={{
        left: {
          tickSize: 1,
          tickPadding: 5,
          tickRotation: 0,
          legend: I18n('Temperature (°C)'),
          legendOffset: -40,
          legendPosition: 'middle',
        },
        bottom: {
          tickSize: 10,
          tickValues: 12,
          tickRotation: 0,
          tickFormat: weekArray(),
          legend: I18n('days', true),
          legendOffset: 50,
          legendPosition: 'middle',
        },
      }}
    />
  );
}
