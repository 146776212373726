import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportPowerConsumptionWeekly() {
  const {
    electric: {
      power: {
        weeklyConsumption: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  let dataOverview = [];
  const { data = { reportPowerConsumptionWeekly: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
    skip: skip({ yearStart, yearEnd, weekStart, weekEnd, dataPoint }),
  });

  const { unit, total_consumption } = data.reportPowerConsumptionWeekly;
  dataOverview.push({
    color: '#F2DA5E',
    text: 'power_consumption',
    data: data.reportPowerConsumptionWeekly.data || [],
  });
  return { loading, unit, total: total_consumption, data: dataOverview || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportPowerConsumptionWeekly(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dataPoint: String!
  ) {
    reportPowerConsumptionWeekly: ReportPowerConsumptionWeekly(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dataPoint: $dataPoint
    ) {
      _id
      unit
      total_consumption
      data {
        date
        value
      }
    }
  }
`;
