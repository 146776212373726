import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useHistoricLog } from 'apollo/filter-pressure';
import HistoricLog from './graph';
import { Filters } from './filters';

const stateName = 'waterQuality.filterPressure.graphicLog';

export function IndividualGraphicLog() {
  const [threshold, setThreshold] = useState({});
  const { data, loading } = useHistoricLog();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);
  const {
    waterQuality: {
      filterPressure: {
        graphicLog: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      const data = dataPoints.find((key) => {
        return key._id === dataPoint;
      });
      setThreshold(data.setting.pressure.threshold);
    }
  }, [dataPoint, dataPoints]);

  return (
    <Container>
      <Filters stateName={stateName} />
      <HistoricLog data={data} loading={loading} threshold={threshold} />
    </Container>
  );
}
