import { useHistory } from 'react-router-dom';
import { GetWaterPumpById } from 'apollo/configuration';
import { Text, Spin, FormCard } from 'components';
import useComponents from 'hooks/useComponents';
import { I18n } from 'i18n';
import { Form } from 'antd';
import { Save } from '../components/water-pump-create/save';

const stateName = 'configuration.waterpumps';

export function WaterForm(props) {
  const { _id, onCloseDrawer } = props;
  const history = useHistory();
  const { data: dataWaterPumpById, isLoading, error } = GetWaterPumpById({ _id });
  const {
    onChange,
    configuration: { waterpumps },
  } = useComponents();

  if (_id && error) history.push('/error-server');

  return (
    <>
      {!isLoading ? (
        <>
          <Form layout="vertical">
            <FormCard
              title={I18n('basic_water_pumps_information')}
              description={I18n('configure_all_general_data_points_information')}
            >
              <Text
                value={waterpumps?.code}
                default={dataWaterPumpById.code}
                onChange={onChange}
                stateName={`${stateName}.code`}
                placeholder={`${I18n('code', true)}:*`}
                externalLabel
                required={true}
              />

              <Text
                value={waterpumps?.url}
                default={dataWaterPumpById.url}
                onChange={onChange}
                stateName={`${stateName}.url`}
                placeholder={`${I18n('url', true)}:*`}
                externalLabel
                required={true}
              />
            </FormCard>
          </Form>
        </>
      ) : (
        <Spin spinning={isLoading} />
      )}
      <div className="rk-button-group">
        <Save _id={_id} onCloseDrawer={onCloseDrawer} />
      </div>
    </>
  );
}
