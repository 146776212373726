const waterpumps = (state = [], action) => {
  switch (action.type) {
    case 'waterPump.data':
      return Object.assign([], state, [...action.waterpumps]);
    case 'waterPump.reset':
      return Object.assign([], state, []);
    default:
      return state;
  }
};

export default waterpumps;
