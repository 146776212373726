import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { PageErro500, PagesError404 } from 'components';
import { useEnvironment } from './environment';
import { useWater } from './water';
import { useElectric } from './electric';
import Configuration from './configuration';
import { Profile } from '../profile';
import { LayoutHeader } from './components';
import './style.less';

export function AppLayout() {
  const history = useHistory();
  const { pathname } = useLocation(); // ruta en concreto donde se para el usuario
  const location = pathname.split('/');
  const ROUTES_WATER = useWater();
  const ROUTES_ENVIRONMENT = useEnvironment();
  const ROUTES_ELECTRIC = useElectric();
  const modulesCustomers = useSelector(({ customers: { data } }) => data.modules);
  const modules = modulesCustomers.map(({ name }) => name);

  // Paths por modulos
  const LINKTO = useMemo(() => {
    return {
      max_and_min_temperature: '/environment/max-and-min-temperature/daily-graph',
      temperature_and_humidity: '/environment/temperature-and-humidity/daily-graph',
      water_preparation: '/water-quality/water-preparation/daily-graph',
      filter_pressure: '/water-quality/filter-pressure/daily-graph',
      gravity_filter: '/water-quality/gravity-filter/daily-graph',
      filter_orp: '/water-quality/filter-orp/daily-graph',
      filter_flowrates: '/water-quality/filter-flowrates/daily-graph',
      filter_tank_levels: '/water-quality/filter-tank-level/daily-graph',
      motor_pumps: '/water-quality/motor-pumps/table',
      voltage_and_amperage: '/electric/voltage-and-amperage/daily-graph',
    };
  }, []);

  const LINKTOCONFIGURATION = useMemo(() => {
    return {
      users: '/configuration/users',
      data_points: '/configuration/data-points',
      devices: '/configuration/devices',
      thresholds: '/configuration/thresholds',
      waterpump: '/configuration/waterpumps',
    };
  }, []);

  useEffect(() => {
    if (!location[1] && !location[2]) return history.push(`${LINKTO[modules[0]]}`);
    const urlModule = location[2].replace(/-/g, '_');
    if (location[1] === 'configuration' && !LINKTO[urlModule])
      return history.push(`${LINKTOCONFIGURATION[urlModule]}`);
    if (!modules.includes([urlModule])) return history.push(`${LINKTO[modules[0]]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', background: '#dde2ed' }}>
      <LayoutHeader />
      <Switch>
        {ROUTES_ENVIRONMENT.map((r) => (
          <Route key={r.name} path={`/environment/${r.path}`} component={r.component} />
        ))}
        {ROUTES_WATER.map((r) => (
          <Route key={r.name} path={`/water-quality/${r.path}`} component={r.component} />
        ))}
        {ROUTES_ELECTRIC.map((r) => (
          <Route key={r.name} path={`/electric/${r.path}`} component={r.component} />
        ))}
        <Route path="/configuration" component={Configuration} />
        <Route path="/error-server" component={PageErro500} />
        <Route path="/404" component={PagesError404} />
      </Switch>
      <Profile />
    </Layout>
  );
}
