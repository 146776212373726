import { useMemo } from 'react';
import { useSelector } from 'react-redux';

//componets
import { Form } from 'antd';
import { FormCard, Select } from 'components';

import { Text, Number } from 'components';

//I18n
import { I18n } from 'i18n';

//utils
import { Transmision, ModulesTemetry } from 'utils';

export function InformacionDataPoint(props) {
  const { dataPointsById, dataPoints, onChange, stateName } = props;
  const { modules } = useSelector(({ customers }) => customers.data);
  const selectModule = useMemo(() => {
    return modules.map(({ name, type }) => ({
      value: name,
      name: I18n(name, true),
      OptGroup: I18n(type, true),
    }));
  }, [modules]);

  const valuesTransmision = useMemo(() => {
    return Transmision.map((key) => ({
      value: key,
      name: key,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Transmision]);

  return (
    <article style={{ width: '50%' }}>
      <FormCard
        title={I18n('basic_data_points_information')}
        description={I18n('configure_all_general_data_points_information')}
      >
        <Text
          value={dataPoints.name}
          default={dataPointsById.name}
          onChange={onChange}
          stateName={`${stateName}.name`}
          placeholder={`${I18n('name', true)}:*`}
          externalLabel
        />
        <Number
          defaultValue={dataPointsById.longitude}
          onChange={onChange}
          step={0.000001}
          stateName={`${stateName}.longitude`}
          placeholder={`${I18n('longitude', true)}:*`}
          externalLabel
        />
        <Number
          defaultValue={dataPointsById.latitude}
          onChange={onChange}
          stateName={`${stateName}.latitude`}
          placeholder={`${I18n('latitude', true)}:*`}
          externalLabel
          step={0.000001}
        />
      </FormCard>
      <FormCard
        title={I18n('module', true)}
        description={I18n('select_a_module_for_the_data_point')}
      >
        <Form.Item>
          <Select
            default={dataPointsById.module}
            label={`${I18n('modules', true)}:*`}
            stateName={`${stateName}.module`}
            data={selectModule}
            placeholder={I18n('modules', true)}
          />
        </Form.Item>
        {ModulesTemetry.includes(dataPoints.module) && (
          <Form.Item labelCol={{ span: 24 }} label={`${I18n('transmision', true)}:*`}>
            <Select
              externalLabel
              default={dataPointsById.time}
              label={`${I18n('transmision', true)}:*`}
              stateName={`${stateName}.time`}
              data={valuesTransmision}
              placeholder={I18n('transmision', true)}
            />
          </Form.Item>
        )}
        {['filter_tank_levels'].includes(dataPoints.module) && (
          <Form.Item labelCol={{ span: 24 }} label={`${I18n('diameter', true)}:*`}>
            <Number
              defaultValue={props.dataPointsById.latitude}
              onChange={onChange}
              stateName={`${stateName}.diameter`}
              placeholder={`${I18n('diameter', true)}:*`}
              step={0.01}
            />
          </Form.Item>
        )}
      </FormCard>
      <FormCard
        title={I18n('location', true)}
        description={I18n('configuration_where_the_location_of_the_data_point')}
      >
        <Text
          value={dataPoints.location}
          default={props.dataPointsById.location}
          onChange={onChange}
          stateName={`${stateName}.location`}
          placeholder={`${I18n('location', true)}`}
          externalLabel={false}
        />
      </FormCard>
    </article>
  );
}
