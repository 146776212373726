import { I18n } from 'i18n';
import { Button } from 'antd';
import { useState } from 'react';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import { WaterTurn } from 'apollo/configuration';

export function Turn({ _id, power_status }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await WaterTurn({ _id });
    if (error) {
      Toast.error({ key: 'error-save-waterpump', message: I18n(message, true) });
    }
    if (!error && data) {
      history.push('/water-quality/motor-pumps/table');
      Toast.success({
        key: 'success-save-water-pumps',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <Button
      loading={isLoading}
      type="primary"
      ghost
      className="rk-button-primary"
      onClick={(e) => handleClick(e)}
    >
      {power_status ? `${I18n('turn_off', true)}` : `${I18n('turn_on', true)}`}
    </Button>
  );
}
