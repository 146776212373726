import { gql, useQuery } from '@apollo/client';

export function GetPumpWaters() {
  const { data = {}, loading, error } = useQuery(GET_PUMPWATERSBYCUSTOMER);
  return { loading, data: data.WaterPumpsByCustomer || [], error };
}

const GET_PUMPWATERSBYCUSTOMER = gql`
  query WaterPumpsByCustomer {
    WaterPumpsByCustomer {
      _id
      code
      url
      customerId
      active
      power_status
    }
  }
`;
