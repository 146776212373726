//componets
import { FormCard } from 'components';

import { Number } from 'components';

//I18n
import { I18n } from 'i18n';

//utils
import { SettingDataPoint } from 'utils';

export function MaximumAxisDataPoint(props) {
  const { dataPointsById, dataPoints, onChange, stateName, codeId } = props;
  return (
    <article style={{ width: '50%' }}>
      <FormCard
        title={I18n('maximum_value_on_the_axisy', true)}
        description={I18n('setting_the_maximum_value_for_the_graph_axis')}
      >
        {Object.keys(
          codeId
            ? dataPointsById.setting
            : SettingDataPoint[dataPoints.module || 'temperature_and_humidity'],
        ).map((value, i) => {
          return (
            <Number
              key={i}
              defaultValue={codeId ? dataPointsById.setting[value]?.yValuesMax : 100}
              onChange={onChange}
              stateName={`${stateName}.setting.${[value]}.yValuesMax`}
              placeholder={`${I18n(`${value}`, true)}:*`}
              externalLabel={`${I18n(`${value}`, true)}:*`}
              step={0}
            />
          );
        })}
      </FormCard>
    </article>
  );
}
