import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function WaterTurn(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: TURN_WATERPUMP,
        variables,
        skip: skip(variables),
        refetchQueries: ['WaterPumpsByCustomer', 'WaterPumpsById'],
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            WaterPumpsTurnByCustomer: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { _id } = variables;
  skip = ![_id].every((v) => v);
  return skip;
}

const TURN_WATERPUMP = gql`
  mutation WaterPumpsTurnByCustomer($_id: ID!) {
    WaterPumpsTurnByCustomer(_id: $_id) {
      error
      data {
        _id
        code
        url
        customerId
        active
        power_status
      }
      message
    }
  }
`;
