import { useEffect, useState } from 'react';
import moment from 'moment';
import { Menu as AntdMenu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { I18n } from 'i18n';
import client from 'apollo/apollo-client';
import { hasPermission, hasPermissionUser } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faIndent,
  faBell,
  faEllipsisV,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  Hamburger,
  Menu,
  ContainerSignOut,
  SubMenu,
  ItemGroup,
  SubMenuItem,
  Profile,
  Divider,
  MenuItem,
  SignOut,
  MyAccount,
  ContainerAvatars,
  Notification,
  ContainerNotification,
  SignOutOption,
} from './style';

export function LayoutHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const location = pathname.split('/');
  const rolesByAccess = useSelector(({ rolesByAccess }) => rolesByAccess);
  const { collapseNav } = useSelector(({ app }) => app);
  const { roles, name: userName, type } = useSelector(({ user: { data } }) => data);
  const { modules } = useSelector(({ customers: { data } }) => data);
  const [date, setDate] = useState(new Date());
  const [sun, setSun] = useState(true);
  const [datetime, setDatetime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const sun = date.getHours() > 6 && date.getHours() < 18;
    let dateformat = moment(date).format('LLLL');
    const currentDate = `${I18n(`current_datetime`, true)} ${dateformat}`;
    setDatetime(currentDate);
    setSun(sun);
  }, [date]);

  const setCollapseNav = (e) => {
    e.preventDefault();
    dispatch({ type: 'app.collapsedNav', collapse: !collapseNav });
  };

  const removeCustomer = () => {
    localStorage.removeItem('auth:customerId');
    dispatch({ type: 'customer.reset' });
    history.push('/');
    window.location.reload();
  };

  const signOut = () => {
    localStorage.clear();
    dispatch({ type: 'user.reset' });
    dispatch({ type: 'customer.reset' });
    client.resetStore();
    history.push('/');
    window.location.reload();
  };

  const onProfile = () => {
    dispatch({ type: 'app.profile', profile: true });
  };

  const menu = (
    <AntdMenu>
      <SubMenuItem onClick={() => removeCustomer()}>
        <FontAwesomeIcon icon={faUsers} /> {I18n('change_customer', true)}
      </SubMenuItem>
      <SubMenuItem onClick={() => signOut()}>
        <FontAwesomeIcon icon={faSignOutAlt} /> {I18n('sign_off', true)}
      </SubMenuItem>
    </AntdMenu>
  );

  const notification = <AntdMenu></AntdMenu>;

  return (
    <Header collapseNav={collapseNav}>
      <Hamburger onClick={(e) => setCollapseNav(e)}>
        <FontAwesomeIcon icon={faIndent} />
      </Hamburger>
      <Profile.Container>
        <Profile.Message>
          <h2>{`${I18n('hi', true)} ${userName}`}</h2>
          <h3>{`${datetime} ${sun ? '☀️' : '🌙'}`}</h3>
        </Profile.Message>
      </Profile.Container>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={[location[1]]}
        style={{ lineHeight: '60px', backgroundColor: 'transparent' }}
      >
        <SubMenu key="environment" title={I18n('environment', true, true)}>
          <ItemGroup title={I18n('capture', true)}>
            <SubMenuItem
              key="max-and-mini-temperature"
              disabled={hasPermission(modules, 'max_and_min_temperature')}
            >
              <Link to="/environment/max-and-min-temperature/daily-graph">
                {I18n('max_and_min_temperature', true)}
              </Link>
            </SubMenuItem>
          </ItemGroup>
          <ItemGroup title={I18n('telemetry', true)}>
            <SubMenuItem
              key="temperature_and_humidity"
              disabled={hasPermission(modules, 'temperature_and_humidity')}
            >
              <Link to="/environment/temperature-and-humidity/daily-graph">
                {I18n('temperature_and_humidity', true)}
              </Link>
            </SubMenuItem>
          </ItemGroup>
        </SubMenu>
        <SubMenu key="water" title={I18n('water', true, true)}>
          <ItemGroup title={I18n('capture', true)}>
            <SubMenuItem
              key="water_preparation"
              disabled={hasPermission(modules, 'water_preparation')}
            >
              <Link to="/water-quality/water-preparation/daily-graph">
                {I18n('water_preparation', true)}
              </Link>
            </SubMenuItem>
            <SubMenuItem key="ORP_calibration" disabled={hasPermission(modules, 'ORP_calibration')}>
              <Link to="/water-quality/ORP-calibration/daily-graph">
                {I18n('ORP_calibration', true)}
              </Link>
            </SubMenuItem>
          </ItemGroup>
          <ItemGroup title={I18n('telemetry', true)}>
            <SubMenuItem
              key="filter_flowrates"
              disabled={hasPermission(modules, 'filter_flowrates')}
            >
              <Link to="/water-quality/filter-flowrates/daily-graph">
                {I18n('flowrates', true)}
              </Link>
            </SubMenuItem>
            <SubMenuItem
              key="filter_tank_levels"
              disabled={hasPermission(modules, 'filter_tank_levels')}
            >
              <Link to="/water-quality/filter-tank-level/daily-graph">
                {I18n('tank_level', true)}
              </Link>
            </SubMenuItem>
            <SubMenuItem key="filter_pressure" disabled={hasPermission(modules, 'filter_pressure')}>
              <Link to="/water-quality/filter-pressure/daily-graph">
                {I18n('filter_pressure', true)}
              </Link>
            </SubMenuItem>
            <SubMenuItem key="gravity_filter" disabled={hasPermission(modules, 'gravity_filter')}>
              <Link to="/water-quality/gravity-filter/daily-graph">
                {I18n('gravity_filter', true)}
              </Link>
            </SubMenuItem>
            <SubMenuItem key="filter_orp" disabled={hasPermission(modules, 'filter_orp')}>
              <Link to="/water-quality/filter-orp/daily-graph">{I18n('orp', true, true)}</Link>
            </SubMenuItem>
            <SubMenuItem key="motor_pumps" disabled={hasPermission(modules, 'motor_pumps')}>
              <Link to="/water-quality/motor-pumps/table">{I18n('motor_pumps', true)}</Link>
            </SubMenuItem>
          </ItemGroup>
        </SubMenu>
        <SubMenu key="electric" title={I18n('electric', true, true)}>
          {/* <ItemGroup title={I18n('capture', true)}>
            <SubMenuItem
              key="max-and-mini-temperature"
              disabled={hasPermission(modules, 'max_and_min_temperature')}
            >
              <Link to="/environment/max-and-min-temperature/daily-graph">
                {I18n('max_and_min_temperature', true)}
              </Link>
            </SubMenuItem>
          </ItemGroup> */}
          <ItemGroup title={I18n('telemetry', true)}>
            <SubMenuItem
              key="voltage_and_amperage"
              disabled={hasPermission(modules, 'voltage_and_amperage')}
            >
              <Link to="/electric/voltage-and-amperage/daily-graph">
                {I18n('voltage_and_amperage', true)}
              </Link>
            </SubMenuItem>
          </ItemGroup>
        </SubMenu>
        <MenuItem
          key="configuration"
          disabled={hasPermissionUser(roles, rolesByAccess, 'configuration')}
        >
          <Link to="/configuration/users">{I18n('configuration', true, true)}</Link>
        </MenuItem>
      </Menu>
      <Divider type="vertical" />
      <ContainerAvatars>
        <Notification overlay={notification}>
          <ContainerNotification dot={false}>
            <FontAwesomeIcon icon={faBell} />
          </ContainerNotification>
        </Notification>
        <MyAccount key="SignOut" onClick={() => onProfile()}>
          <Profile.Container>
            <Profile.Avatar>
              <span>{userName[0]}</span>
            </Profile.Avatar>
          </Profile.Container>
        </MyAccount>
        {['internal'].includes(type) ? (
          <SignOut overlay={menu} placement="bottomRight">
            <ContainerSignOut>
              <FontAwesomeIcon icon={faEllipsisV} />
            </ContainerSignOut>
          </SignOut>
        ) : (
          <SignOutOption key="SignOut" onClick={() => signOut()}>
            <ContainerSignOut>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ContainerSignOut>
          </SignOutOption>
        )}
      </ContainerAvatars>
    </Header>
  );
}
