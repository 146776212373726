import { useMemo } from 'react';
import { MenuVoltageAmperage } from './voltage-amperage';

export const useElectric = () => {
  return useMemo(() => {
    return [
      {
        name: 'voltage-and-amperage',
        exact: true,
        path: 'voltage-and-amperage',
        component: MenuVoltageAmperage,
      },
    ];
  }, []);
};
