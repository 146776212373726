import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useReportResponseHumidityLog, useReportResponseTemperatureLog } from 'apollo/weather';
import Temperature from './temperature';
import Humidity from './humidity';
import { Filters } from './filters';

const stateName = 'environment.weather.historicLog';

export function GraphicWeatherRange() {
  const [thresholdTemperature, setThresholdTemperature] = useState({});
  const [yValuesMaxTemperature, setYValuesMaxTemperature] = useState(0);
  const [thresholdHumidity, setThresholdHumidity] = useState({});
  const [yValuesMaxHumidity, setYValuesMaxHumidity] = useState(0);
  const dataTemperature = useReportResponseTemperatureLog();
  const dataHumidity = useReportResponseHumidityLog();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);

  const {
    environment: {
      weather: {
        historicLog: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      const data = dataPoints.find((key) => {
        return key._id === dataPoint;
      });
      setThresholdTemperature(data.setting.temperature.threshold);
      setYValuesMaxTemperature(data.setting.temperature.yValuesMax);
      setThresholdHumidity(data.setting.humidity.threshold);
      setYValuesMaxHumidity(data.setting.humidity.yValuesMax);
    }
  }, [dataPoint, dataPoints]);
  return (
    <Container>
      <Filters stateName={stateName} />
      <Temperature
        data={dataTemperature.data}
        loading={dataTemperature.loading}
        threshold={thresholdTemperature}
        yValuesMax={yValuesMaxTemperature}
      />
      <Humidity
        data={dataHumidity.data}
        loading={dataHumidity.loading}
        threshold={thresholdHumidity}
        yValuesMax={yValuesMaxHumidity}
      />
    </Container>
  );
}
