import { useQuery, gql } from '@apollo/client';

export function usePowerSubQuerys({ yearStart, yearEnd, weekStart, weekEnd }) {
  const variables = { yearStart, yearEnd, weekStart, weekEnd };
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_SUBQUERY, {
    variables,
    skip: skip(variables),
  });

  return { loading, data: { Subqueries: data.Subqueries }, error };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd].every((v) => v);
  return skip;
}

const GET_SUBQUERY = gql`
  query ResponsePowerRangeSubqueries(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
  ) {
    Subqueries: ResponsePowerRangeSubqueries(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
    ) {
      _id
      data_point
    }
  }
`;
