import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function pumpsConfigure(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_WATERPUMPS,
        variables,
        skip: skip(variables),
        refetchQueries: ['WaterPumpsByCustomer', 'WaterPumpsById'],
      })
      .then(
        ({
          data: {
            WaterPumpsConfigure: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { code, url } = variables;
  skip = ![code, url].every((v) => v);
  return skip;
}

const CONFIGURE_WATERPUMPS = gql`
  mutation WaterPumpsConfigure($_id: ID, $code: String!, $url: String!) {
    WaterPumpsConfigure(_id: $_id, code: $code, url: $url) {
      error
      data {
        _id
        code
        url
      }
      message
    }
  }
`;
