import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AndtInput, Form } from 'antd';
import { I18n } from 'i18n';

function getValid(value, min, max, re) {
  if (!isNaN(value)) {
    // if (min && value < min) return false;
    // if (max && value > max) return false;
    return true;
  }
  return false;
}

export const Number = (props) => {
  let {
    disabled,
    value,
    externalLabel,
    noFormItem,
    placeholder,
    className,
    size,
    style,
    stateName,
    touched,
    error,
    formItemStyle,
    min,
    max,
    step,
    parser,
    re,
    formatter,
  } = props;

  useEffect(() => {
    if (props.defaultValue) {
      props.onChange(stateName, props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  if (noFormItem) {
    return (
      <AndtInput
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={props.defaultValue}
        min={min}
        max={max}
        size={size || 'middle'}
        step={step}
        value={value}
        style={{ width: '100%', ...style }}
        onChange={(e) => props.onChange(stateName, e, getValid(e, min, max, re))}
        formatter={formatter}
        parser={parser}
      />
    );
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      help={touched || error ? error : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      style={formItemStyle}
      label={externalLabel ? I18n(placeholder, true) : false}
    >
      <AndtInput
        className={className}
        disabled={disabled}
        size={size || 'middle'}
        placeholder={placeholder}
        defaultValue={props.defaultValue}
        min={min}
        max={max}
        step={step}
        value={value}
        style={{ width: '100%', ...style }}
        onChange={(e) => props.onChange(stateName, e, getValid(e, min, max, re))}
        formatter={formatter}
        parser={parser}
      />
    </Form.Item>
  );
};

Number.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
