import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useReportResponseAmperageLog, useReportResponseVoltageLog } from 'apollo/power';
import Voltage from './voltage';
import Amperage from './amperage';
import { Filters } from './filters';

const stateName = 'electric.power.historicLog';

export function GraphicPowerRange() {
  const [thresholdVoltage, setThresholdVoltage] = useState({});
  const [yValuesMaxVoltage, setYValuesMaxVoltage] = useState(0);
  const [thresholdAmperage, setThresholdAmperage] = useState({});
  const [yValuesMaxAmperage, setYValuesMaxAmperage] = useState(0);
  const dataVoltage = useReportResponseVoltageLog();
  const dataAmperage = useReportResponseAmperageLog();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);

  const {
    electric: {
      power: {
        historicLog: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      const data = dataPoints.find((key) => {
        return key._id === dataPoint;
      });
      setThresholdVoltage(data.setting.voltage.threshold);
      setYValuesMaxVoltage(data.setting.voltage.yValuesMax);
      setThresholdAmperage(data.setting.amperage.threshold);
      setYValuesMaxAmperage(data.setting.amperage.yValuesMax);
    }
  }, [dataPoint, dataPoints]);
  return (
    <Container>
      <Filters stateName={stateName} />
      <Amperage
        data={dataAmperage.data}
        loading={dataAmperage.loading}
        threshold={thresholdAmperage}
        yValuesMax={yValuesMaxAmperage}
      />
      <Voltage
        data={dataVoltage.data}
        loading={dataVoltage.loading}
        threshold={thresholdVoltage}
        yValuesMax={yValuesMaxVoltage}
      />
    </Container>
  );
}
