import React from 'react';
import { Layout, Breadcrumb, Divider } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { I18n } from 'i18n';

export function Main({ ROUTES, collapseNav, breadcrumb }) {
  return (
    <Layout>
      <Layout.Content className={`rk-layout-content ${collapseNav && `collapse-nav`}`}>
        <Breadcrumb separator="">
          <Breadcrumb.Item>
            <strong> Estas en: </strong>
          </Breadcrumb.Item>
          {breadcrumb.map((r, i) => {
            const memo = r.replaceAll('-', '_');
            if (i === 0) return null;
            return (
              <>
                <Breadcrumb.Item>{I18n(memo, true)}</Breadcrumb.Item>
                {breadcrumb.length - 1 === i ? null : <Breadcrumb.Separator />}
              </>
            );
          })}
        </Breadcrumb>
        <Divider />
        <Switch>
          {ROUTES.map((r) => (
            <Route exact key={r.name} path={r.path} component={r.component} />
          ))}
        </Switch>
      </Layout.Content>
    </Layout>
  );
}
