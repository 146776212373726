import { useHistory } from 'react-router-dom';
import { Spin, Descriptions } from 'antd';
import { GetDataPointsById } from 'apollo/configuration';
import { I18n } from 'i18n';

import { DataPointState } from '../components';

export function Detail({ codeId, onCloseDrawer }) {
  const history = useHistory();
  const { data: dataDataPointById, isLoading, error } = GetDataPointsById({ codeId });

  if (codeId && error) return history.push('/error-server');

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item label={`${I18n('name', true)} `}>
              {dataDataPointById.name}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('longitude', true)}`}>
              {dataDataPointById.longitude}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('latitude', true)}`}>
              {dataDataPointById.latitude}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('modules', true)}`}>
              {I18n(dataDataPointById.module, true)}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('location', true)}`}>
              {dataDataPointById.location
                ? I18n(dataDataPointById.location, true)
                : I18n('no_location')}
            </Descriptions.Item>
            {['temperature_and_humidity'].includes(dataDataPointById.module) && (
              <Descriptions.Item label={`${I18n('yValuesMax', true)}`}>
                {dataDataPointById.yValuesMax ? dataDataPointById.yValuesMax : I18n('not_apply')}
              </Descriptions.Item>
            )}
            {['filter_tank_levels'].includes(dataDataPointById.module) && (
              <Descriptions.Item label={`${I18n('diameter', true)}`}>
                {dataDataPointById.diameter ? dataDataPointById.diameter : I18n('not_apply')}
              </Descriptions.Item>
            )}
          </Descriptions>
          <div className="rk-button-group">
            <DataPointState
              active={dataDataPointById.active}
              codeId={dataDataPointById._id}
              onCloseDrawer={(e) => onCloseDrawer(e)}
            />
          </div>
        </>
      )}
    </>
  );
}
