import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportDailyResponseVoltageLog() {
  const {
    electric: {
      power: {
        historicDailyLog: { year, week, day, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = { ReportDailyResponseVoltageLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { year, week, day, dataPoint },
    skip: skip({ year, week, day, dataPoint }),
  });
  let dataOverview = [];
  data.ReportDailyResponseVoltageLog.forEach((rows) => {
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview || [] };
}

function skip(variables) {
  let skip = true;
  let { year, week, day, dataPoint } = variables;
  skip = ![year, week, day, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportDailyResponseVoltageLog($year: Int!, $week: Int!, $day: Int!, $dataPoint: String!) {
    ReportDailyResponseVoltageLog: ReportDailyResponseVoltageLog(
      year: $year
      week: $week
      day: $day
      dataPoint: $dataPoint
    ) {
      _id
      text
      color
      data {
        _id
        hour
        value
      }
    }
  }
`;
