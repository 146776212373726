import { useState } from 'react';
import { I18n } from 'i18n';
import { Button, Popconfirm } from 'antd';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import { WaterPumpState } from 'apollo/configuration';

export function WaterPumpStateButton({ _id, active, onCloseDrawer }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await WaterPumpState({ _id });
    if (error) {
      Toast.error({ key: 'error-save-datapoint', message: I18n(message, true) });
    }
    if (!error && data) {
      onCloseDrawer();
      history.push('/configuration/waterpumps');
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Popconfirm
        disabled={isLoading}
        title={active ? `${I18n('inactive?', true)}` : `${I18n('activate?', true)}`}
        onConfirm={(e) => handleClick(e)}
        okText={I18n('yes', true)}
        cancelText={I18n('no', true)}
      >
        <Button type="primary" ghost className="rk-button-primary" disabled={isDisabled({ _id })}>
          {active ? `${I18n('inactivate', true)}` : `${I18n('activate', true)}`}
        </Button>
      </Popconfirm>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { _id } = variables;
  disabled = ![_id].every((v) => v);
  return disabled;
}
