import { useState, useCallback } from 'react';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faTimesCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Table as TableUI, Button, Tag } from 'antd';
import { GetUsers } from 'apollo/configuration';
import { PageContent, Avatar, Drawer } from 'components';
import { Detail } from './detail-user';
import { AssignUser } from './assign-user';
import { UserForm } from './user-create';

const { Column } = TableUI;

export function ListUsers() {
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [visible, setVisible] = useState(false);
  const [isUserForm, setIsUserForm] = useState(false);
  const { type } = useSelector(({ user: { data } }) => data);
  const { data: dataUsers, loading: isLoading, error } = GetUsers();

  if (error) history.push('/error-server');

  const onCloseDrawer = () => {
    setUserId('');
    setVisible(false);
    setIsUserForm(false);
  };

  const onShowDrawer = useCallback((action, value) => {
    switch (action) {
      case 'user_create':
        setUserId('');
        setIsUserForm(true);
        break;
      case 'user_detail':
        setUserId(value);
        break;
      default:
    }
    setVisible(true);
  }, []);

  return (
    <>
      <PageContent
        title={I18n('list_user', true, true)}
        backIcon={false}
        extra={[
          ['internal'].includes(type) && (
            <Button type="primary" className="rk-button-primary" onClick={() => onShowDrawer()}>
              {I18n('assign', true)}
            </Button>
          ),
          <Button
            type="primary"
            className="rk-button-primary"
            onClick={() => onShowDrawer('user_create')}
          >
            {I18n('add', true)}
          </Button>,
        ]}
      >
        <TableUI
          pagination={dataUsers.length > 10 ? findPaginator() : false}
          dataSource={dataUsers}
          loading={isLoading}
          bordered
          rowClassName={(record) => !record.active && 'disabled-row'}
          rowKey={(data) => data._id}
          onRow={(user, rowIndex) => {
            return {
              onClick: () => onShowDrawer('user_detail', user._id), // click row
            };
          }}
        >
          <Column
            title={I18n('name', true)}
            dataIndex={'name'}
            key={'name'}
            render={(value) => {
              return <Avatar text={I18n(value, true)} />;
            }}
          />
          <Column
            title={I18n('email', true)}
            dataIndex={'email'}
            key={'email'}
            render={(value, user) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <p>{value}</p>
                  {user.verified ? (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faCheck} />}
                      color={'success'}
                    >
                      {`  ${I18n('verified')}`}
                    </Tag>
                  ) : (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      color={'error'}
                    >
                      {`  ${I18n('no_verified')}`}
                    </Tag>
                  )}
                </div>
              );
            }}
          />
          <Column title={I18n('user_name', true)} dataIndex={'username'} key={'username'} />
          <Column
            title={I18n('permissions', true)}
            dataIndex={'roles'}
            render={(roles) => <div>{I18n(roles.map((r) => I18n(r, true)).join(', '), true)}</div>}
            key={'roles'}
          />
          <Column
            title={I18n('language', true)}
            dataIndex={'language'}
            key={'language'}
            render={(value) => I18n(value, true)}
          />
          <Column
            title={I18n('state', true)}
            user_assigns
            dataIndex={'active'}
            key={'active'}
            render={(value) => {
              return (
                <>
                  {value ? (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faCheck} />}
                      color={'success'}
                    >
                      {`  ${I18n('activate')}`}
                    </Tag>
                  ) : (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      color={'error'}
                    >
                      {`  ${I18n('inactive')}`}
                    </Tag>
                  )}
                </>
              );
            }}
          />
          <Column
            key="action"
            render={() => [
              <span style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </span>,
            ]}
          />
        </TableUI>
        <Drawer
          showTitle={true}
          title={
            <>
              {!userId && isUserForm && I18n('create_user', true, true)}
              {userId && isUserForm && I18n('update_user', true, true)}
              {userId && !isUserForm && I18n('user_information', true, true)}
              {!userId && !isUserForm && I18n('user_assigns', true, true)}
            </>
          }
          placement="right"
          onClose={(e) => onCloseDrawer(e)}
          visible={visible}
          extra={[
            <div className="rk-drawer-actions">
              {userId && !isUserForm && (
                <span onClick={() => setIsUserForm(!isUserForm)}>{I18n('edit', true)}</span>
              )}
              <button
                onClick={(e) => onCloseDrawer(e)}
                type="button"
                aria-label="Close"
                class="ant-drawer-close"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>,
          ]}
        >
          {!userId && isUserForm && <UserForm onCloseDrawer={onCloseDrawer} />}
          {userId && isUserForm && <UserForm userId={userId} onCloseDrawer={onCloseDrawer} />}
          {userId && !isUserForm && (
            <Detail userId={userId} onCloseDrawer={() => onCloseDrawer()} />
          )}
          {!userId && !isUserForm && <AssignUser onCloseDrawer={() => onCloseDrawer()} />}
        </Drawer>
      </PageContent>
    </>
  );
}

function findPaginator() {
  return {
    pageSize: 10,
  };
}
