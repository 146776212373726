import { useQuery, gql } from '@apollo/client';

export function GetDataPointsById({ codeId }) {
  const {
    data = { DataPointsById: {} },
    loading,
    error,
  } = useQuery(GET_DATAPOINTSBYID, {
    variables: { codeId },
    skip: skip({ codeId }),
  });
  return { isLoading: loading, data: data.DataPointsById || {}, error };
}

function skip(variables) {
  let skip = true;
  let { codeId } = variables;
  skip = ![codeId].every((v) => v);
  return skip;
}

const GET_DATAPOINTSBYID = gql`
  query DataPointsById($codeId: ID!) {
    DataPointsById(codeId: $codeId) {
      _id
      name
      customerId
      longitude
      latitude
      active
      module
      location
      time
      diameter
      threshold
      yValuesMax
      setting
    }
  }
`;
