import { Button, Table as TableUI, Tag } from 'antd';
import { Drawer, PageContent } from 'components';
import { I18n } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GetPumpWaters } from 'apollo/configuration';
import { useCallback, useState } from 'react';
import { WaterForm } from '..';
import { DetailWater } from './detail-water-pump';

const { Column } = TableUI;

export function PumpsList() {
  const [id, setId] = useState('');
  const [visible, setVisible] = useState(false);
  const [isWaterPumps, setIsWaterPumpForm] = useState(false);
  const { data: dataPump, loading } = GetPumpWaters();

  const onCloseDrawer = () => {
    setId('');
    setVisible(false);
    setIsWaterPumpForm(false);
  };

  const onShowDrawer = useCallback((action, value) => {
    switch (action) {
      case 'water_pump_create':
        setId('');
        setIsWaterPumpForm(true);
        break;
      case 'water_pump_detail':
        setId(value);
        break;
      default:
    }
    setVisible(true);
  }, []);

  return (
    <>
      <PageContent
        title={I18n('list_waterpumps')}
        backIcon={false}
        extra={[
          <Button
            type="primary"
            className="rk-button-primary"
            onClick={() => onShowDrawer('water_pump_create')}
          >
            {I18n('add', true)}
          </Button>,
        ]}
      >
        <TableUI
          dataSource={dataPump}
          pagination={dataPump.length > 10 ? findPaginator() : false}
          loading={loading}
          bordered
          rowKey={(data) => data.id}
          rowClassName={(record) => !record.active && 'disabled-row'}
          onRow={(data) => {
            return {
              onClick: () => onShowDrawer('water_pump_detail', data.id),
            };
          }}
        >
          <Column title={I18n('code', true)} dataIndex={'code'} key={'code'} />
          <Column title={I18n('url', true)} dataIndex={'url'} key={'url'} />
          <Column
            title={I18n('state', true)}
            dataIndex={'active'}
            key={'active'}
            render={(value) => {
              return (
                <>
                  {value ? (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faCheck} />}
                      color={'success'}
                    >
                      {`  ${I18n('active')}`}
                    </Tag>
                  ) : (
                    <Tag
                      className="rk-tag"
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      color={'error'}
                    >
                      {`  ${I18n('inactive')}`}
                    </Tag>
                  )}
                </>
              );
            }}
          />
        </TableUI>
        <Drawer
          showTitle={true}
          title={
            <>
              {!id && isWaterPumps && I18n('create_data_points', true, true)}
              {id && isWaterPumps && I18n('update_data_points', true, true)}
              {id && !isWaterPumps && I18n('datapoint_information', true, true)}
            </>
          }
          placement="right"
          onClose={(e) => onCloseDrawer(e)}
          visible={visible}
          extra={[
            <div className="rk-drawer-actions">
              {id && !isWaterPumps && (
                <span onClick={() => setIsWaterPumpForm(!isWaterPumps)}>{I18n('edit', true)}</span>
              )}
              <button
                onClick={(e) => onCloseDrawer(e)}
                type="button"
                aria-label="Close"
                class="ant-drawer-close"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>,
          ]}
        >
          {!id && isWaterPumps && <WaterForm onCloseDrawer={(e) => onCloseDrawer(e)} />}
          {id && isWaterPumps && <WaterForm _id={id} onCloseDrawer={(e) => onCloseDrawer(e)} />}
          {id && !isWaterPumps && (
            <DetailWater id={id} onCloseDrawer={(e) => onCloseDrawer(e)}></DetailWater>
          )}
        </Drawer>
      </PageContent>
    </>
  );
}

function findPaginator() {
  return {
    pageSize: 10,
  };
}
