import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'components';
import { InputRows } from 'components';
import DateRange from '../../components/date-range/date-range';
import Subqueries from '../../components/sub-queries/sub-queries';

export function Filters({ stateName }) {
  const {
    electric: {
      power: { historicLog },
    },
  } = useSelector(({ state }) => state);
  const { dataPoints: DataPoints } = useSelector(({ customers }) => customers.data);

  const { yearStart, yearEnd, weekEnd, weekStart } = historicLog;
  return (
    <Subqueries
      stateName={stateName}
      yearStart={yearStart}
      yearEnd={yearEnd}
      weekEnd={weekEnd}
      weekStart={weekStart}
      children
    >
      {({ loading: subLoading, error, data: subqueries }) => {
        let dataPoints = [];

        DataPoints.forEach(({ _id, location }) => {
          if (!subqueries?.data_point.includes(_id)) return;
          dataPoints.push({ value: _id, name: location });
        });
        return [
          <InputRows key={1}>
            <DateRange stateName={stateName} hideMonth />
            <Select
              stateName={`${stateName}.dataPoint`}
              default={subqueries?.data_point[0]}
              data={dataPoints}
              loading={subLoading}
            />
          </InputRows>,
        ];
      }}
    </Subqueries>
  );
}
