import { useHistory } from 'react-router-dom';
import { Spin, Descriptions, Tag } from 'antd';
import { GetWaterPumpById } from 'apollo/configuration';
import { I18n } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WaterPumpStateButton } from '../components';

export function DetailWater({ _id, onCloseDrawer }) {
  const history = useHistory();
  const { data: dataWaterPumpById, isLoading, error } = GetWaterPumpById({ _id });

  if (_id && error) history.push('/error-server');

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <Descriptions size="middle" column={1}>
          <Descriptions.Item label={`${I18n('code', true)}`}>
            {dataWaterPumpById?.code}
          </Descriptions.Item>
          <Descriptions.Item label={`${I18n('url', true)}`}>
            {dataWaterPumpById?.url}
          </Descriptions.Item>
          <Descriptions.Item label={`${I18n('state', true)}`}>
            {dataWaterPumpById?.active ? (
              <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                {`  ${I18n('activate')}`}
              </Tag>
            ) : (
              <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                {`  ${I18n('inactive')}`}
              </Tag>
            )}
          </Descriptions.Item>
        </Descriptions>
      )}
      <div className="rk-button-group">
        <WaterPumpStateButton
          active={dataWaterPumpById.active}
          _id={dataWaterPumpById._id}
          onCloseDrawer={(e) => onCloseDrawer(e)}
        />
      </div>
    </>
  );
}
