import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useReportPowerConsumptionWeekly } from 'apollo/power';
import ConsumptionGraph from '../../components/consumption-graph/consumption-graph';
import { Filters } from './filters';
import { I18n } from 'i18n';

const stateName = 'electric.power.weeklyConsumption';

export function GraphicWeeklyConsumption() {
  const dataWeeklyConsumption = useReportPowerConsumptionWeekly();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);

  const {
    electric: {
      power: {
        weeklyConsumption: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      dataPoints.find((key) => {
        return key._id === dataPoint;
      });
    }
  }, [dataPoint, dataPoints]);

  const { data, loading, total, unit } = dataWeeklyConsumption;

  return (
    <Container>
      <Filters stateName={stateName} />
      <ConsumptionGraph
        data={data}
        loading={loading}
        total={total}
        totalText={`${I18n('consumption', true)} ${I18n('total', true)} = ${total || 0} ${
          unit || 'kWh'
        }`}
        title={`${I18n('weekly_consumption', true)}`}
        leftLegend={`${I18n('consumption', true)} ${unit}`}
        bottomLegend={`${I18n('week', true)}`}
      />
    </Container>
  );
}
