import styled from 'styled-components';
import {
  Layout,
  Menu as AntdMenu,
  Divider as AntdDivider,
  Dropdown as AntdDropdown,
  Badge as AntdBadge,
} from 'antd';

export const Header = styled(Layout.Header)`
  padding: 0px 0px 0px 0px !important;
  height: 64px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  transition: all 0.2s;
  position: fixed;
  width: calc(100% - ${({ collapseNav }) => (!collapseNav ? `250px` : `0px`)});
  margin-left: ${({ collapseNav }) => (!collapseNav ? `250px` : `0px`)};
  z-index: 99;
`;

export const Profile = {
  Container: styled.div`
    display: flex;
    align-items: center;
  `,
  Avatar: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 8px;
    background-color: #4f5d75;
    > span {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
    }
  `,
  Message: styled.div`
    padding-left: 20px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    *:first-child {
      color: #fff;
      margin: 0px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
    *:last-child {
      margin-top: 2px;
      line-height: 18px;
      color: #fff;
      margin: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  `,
};

export const Menu = styled(AntdMenu)`
  color: #fff !important;
  margin-left: auto;
  width: 485px;
  border-bottom: 0px solid #f0f0f0 !important;
  height: 60px !important;
  > .ant-menu-submenu-selected > div,
  .ant-menu-item-selected {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 0;
    background-color: #4f5d75 !important;
  }
  > .ant-menu-submenu-open > div {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 0;
    background-color: #4f5d75 !important;
  }
`;

export const MenuItem = styled(AntdMenu.Item)`
  > a,
  > .ant-menu-title-content > a {
    color: #fff !important;
  }
  :hover {
    color: #272e3a !important;
    background-color: #dce0e7;
    height: 62px;
    background-color: #4f5d75 !important;
  }
  :active {
    height: 62px;
    color: #272e3a !important;
    background-color: #dce0e7;
    background-color: #4f5d75 !important;
  }
  :disabled {
    height: 62px;
    color: #dce0e7;
    background: #ccc;
  }
  :after {
    border-bottom: 0px !important;
  }
`;

export const SubMenuItem = styled(AntdMenu.Item)`
  > a,
  > .ant-menu-title-content > a {
    color: #272e3a !important;
  }
  :hover {
    color: #272e3a !important;
    background-color: #dce0e7;
  }
  :active {
    color: #272e3a !important;
    background-color: #dce0e7;
  }
  :disabled {
    color: #dce0e7;
    background: #ccc;
  }
`;

export const ContainerAvatars = styled.div`
  display: flex;
  gap: 0.5em;
  margin: 0px 1rem 0px 3rem !important;
`;

export const MyAccount = styled.div`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 62px;
  padding: 5px 20px;
  top: 1px;
  text-align: center;
  :hover {
    cursor: pointer;
    height: 62px;
  }
`;

export const Notification = styled(AntdDropdown)`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 62px;
  padding: 0 20px;
  top: 1px;

  text-align: center;
  :hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 1px;
    vertical-align: top !important;
  }
`;

export const ContainerNotification = styled(AntdBadge)`
  text-align: center;
  line-height: 60px;
  svg {
    font-size: 20px;
  }
  sup {
    margin-top: 15px;
    right: 18px;
  }
`;

export const SignOut = styled(AntdDropdown)`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 62px;
  padding: 0 20px;
  top: 1px;

  text-align: center;
  :hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 1px;
    vertical-align: top !important;
  }
  :active {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 1px;
    background-color: #4f5d75 !important;
    vertical-align: top !important;
  }
  :after {
    border-bottom: 0px !important;
  }
`;

export const BottonList = styled(AntdMenu.Item)`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 60px;
  width: 100px;
  top: 0;
  text-align: center;
  :hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 60px;
    width: 100px;
    top: 0;
    background-color: #4f5d75 !important;
    vertical-align: top !important;
  }
  :active {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 60px;
    top: 0;
    background-color: #4f5d75 !important;
    vertical-align: top !important;
  }
`;

export const SubMenu = styled(AntdMenu.SubMenu)`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 60px;
  width: auto;
  text-align: center;
  padding: 0 5px 0 5px !important;
  .ant-menu-submenu-title {
    padding: 0 20px 0 20px !important;
  }
  .ant-menu-submenu-title:hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 0;
    background-color: #4f5d75 !important;
  }
  :after {
    border-bottom: 0px !important;
  }
  :active {
    color: #fff !important;
    border-bottom: 0px !important;
    padding: 0 5px 0 5px !important;
    cursor: pointer;
    height: 59px;
    top: 0;
    background-color: #4f5d75 !important;
  }
`;

export const ItemGroup = styled(AntdMenu.ItemGroup)`
  color: #272e3a !important;
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    background: #fff;
    .ant-menu-title-content > a {
      color: #c3c5cc !important;
    }
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled > span > a {
    color: #c3c5cc !important;
  }
`;

export const ContainerSignOut = styled.div`
  text-align: center;
  line-height: 60px;
  svg {
    font-size: 20px;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  width: 240px;
  transition: width 0.2s;
  background: #fff;
  height: 64px;
  border-bottom: 1px solid #dde2ed !important;
  border-right: 1px solid #dde2ed !important;
  .container-logo {
    display: flex;
    width: 100%;
    padding: 5px;
    > .bg-isoogo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      border-radius: 8px;
      border: 2px solid #374052;
      img {
        width: 32px;
        height: 32px;
      }
    }
    > .rk-module {
      padding-left: 10px;
      padding-top: 2px;
      width: 100%;
      height: 48px;
      line-height: 48px;
      *:first-child {
        color: #374052;
        margin: 0px;
        line-height: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
      }
      *:last-child {
        color: #374052;
        margin-top: 2px;
        line-height: 18px;
        margin: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`;

export const Hamburger = styled.div`
  padding: 0px 20px;
  height: 60px;
  align-items: center;
  align-content: center;
  display: flex;
  font-size: 20px;
  color: #fff !important;
  :hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
  }
`;

export const Divider = styled(AntdDivider)`
  height: 2.9em;
  border-left: 2px solid white;
`;

export const SignOutOption = styled.div`
  border-bottom: 0px !important;
  color: #fff !important;
  height: 62px;
  padding: 0 20px;
  top: 1px;

  text-align: center;
  :hover {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 1px;
    background-color: #4f5d75 !important;
    vertical-align: top !important;
  }
  :active {
    color: #fff !important;
    border-bottom: 0px !important;
    cursor: pointer;
    height: 62px;
    top: 1px;
    background-color: #4f5d75 !important;
    vertical-align: top !important;
  }
  :after {
    border-bottom: 0px !important;
  }
`;
