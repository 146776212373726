import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useHistoricLog } from 'apollo/filter-orp';
import { Filters } from './filter';
import { GraphicLog } from './graph';

const stateName = 'waterQuality.filterOrp.graphicLog';

export function HistoricLog() {
  const [threshold, setThreshold] = useState({});
  const { data, loading } = useHistoricLog();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);
  const {
    waterQuality: {
      filterOrp: {
        graphicLog: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      const data = dataPoints.find((key) => {
        return key._id === dataPoint;
      });
      setThreshold(data.threshold);
    }
  }, [dataPoint, dataPoints]);

  return (
    <Container>
      <Filters stateName={stateName} />
      <GraphicLog data={data} loading={loading} threshold={threshold} />
    </Container>
  );
}
