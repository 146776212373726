import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportResponseVoltageLog() {
  const {
    electric: {
      power: {
        historicLog: { yearStart, yearEnd, dayStart, weekStart, weekEnd, dayEnd, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = { HistoricFlowratesLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { yearStart, yearEnd, dayStart, weekStart, weekEnd, dayEnd, dataPoint },
    skip: skip({ yearStart, yearEnd, dayStart, weekStart, weekEnd, dayEnd, dataPoint }),
  });
  return { loading, data: data.ReportResponseVoltageLog || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, dayStart, weekStart, weekEnd, dayEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, dayStart, weekStart, weekEnd, dayEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportResponseVoltageLog(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dayStart: Int!
    $dayEnd: Int!
    $dataPoint: String!
  ) {
    ReportResponseVoltageLog(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dayStart: $dayStart
      dayEnd: $dayEnd
      dataPoint: $dataPoint
    ) {
      _id
      text
      color
      data {
        hour
        value
      }
    }
  }
`;
