import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Text, ColorPicker, Select, FormCard, Spin } from 'components';
import { I18n } from 'i18n';
import { generateUUID } from 'utils';
import useComponents from 'hooks/useComponents';
import { GetDeviceById } from 'apollo/configuration';
import { GetDataPointsByModuleTemetry } from 'apollo/configuration';
import { Save } from '../components/devices-create';

const stateName = 'configuration.devices';

const TYPE_DEVICES = () => [
  {
    value: 'sensor_pressure_entry',
    name: I18n('sensor_pressure_entry', true),
    text: I18n('sensor_pressure_entry', true),
  },
  {
    value: 'sensor_pressure_exit',
    name: I18n('sensor_pressure_exit', true),
    text: I18n('sensor_pressure_exit', true),
  },
  { value: 'Sensor_ORP', name: I18n('Sensor_ORP', true), text: I18n('Sensor_ORP', true) },
  {
    value: 'Sensor_flowrates',
    name: I18n('Sensor_flowrates', true),
    text: I18n('Sensor_flowrates', true),
  },
  {
    value: 'Sensor_tank_level',
    name: I18n('Sensor_tank_level', true),
    text: I18n('Sensor_tank_level', true),
  },
  {
    value: 'sensor_temperature_and_humidity',
    name: I18n('sensor_temperature_and_humidity', true),
    text: I18n('sensor_temperature_and_humidity', true),
  },
  {
    value: 'sensor_voltage_and_amperage',
    name: I18n('sensor_voltage_and_amperage', true),
    text: I18n('sensor_voltage_and_amperage', true),
  },
];

export function DevicesForm(props) {
  const customer = useSelector(({ customers }) => customers.data);
  const { _id, onCloseDrawer } = props;
  const history = useHistory();
  const makeCode = generateUUID(customer._id);
  const { data, loading, error } = GetDeviceById(_id);
  const [codeId, setCodeId] = useState(makeCode);
  const useDataPoints = GetDataPointsByModuleTemetry();

  const {
    state: {
      configuration: { devices },
    },
    onChange,
  } = useComponents();
  useEffect(() => {
    if (data.code) {
      setCodeId(data.code);
    }
  }, [data.code]);

  if (_id && error) history.push('/error-server');

  return (
    <>
      {!loading ? (
        <Form layout="vertical">
          <FormCard title={I18n('basic_sensor_information', true)}>
            <Text
              default={data?.name}
              onChange={onChange}
              stateName={`${stateName}.name`}
              placeholder={`${I18n('name', true)}:*`}
              externalLabel
            />
            <Text
              disabled={true}
              tooltip="Este código es el que permite conectar el sensor con nuestra aplicación"
              value={codeId}
              default={codeId}
              onChange={onChange}
              stateName={`${stateName}.code`}
              placeholder={`${I18n('code', true)}:*`}
              externalLabel
            />
            <ColorPicker
              required={true}
              defaultValue={data.color}
              value={devices.color}
              onChange={onChange}
              stateName={`${stateName}.color`}
              placeholder={`${I18n('color', true)}:*`}
              isFormItem
            />
          </FormCard>
          <FormCard
            title={I18n('type', true)}
            description={I18n('select_what_data_the_sensor_will_capture', true)}
          >
            <Select
              style={{ width: '100%' }}
              default={data.type}
              label={`${I18n('type', true)}:*`}
              onChange={onChange}
              stateName={`${stateName}.type`}
              data={TYPE_DEVICES()}
              placeholder={`${I18n('type', true)}:*`}
            />
          </FormCard>
          <FormCard
            title={`${I18n('data_point', true)}:*`}
            description={I18n('select_the_data_capture_site', true)}
          >
            <Select
              style={{ width: '100%' }}
              default={data.data_point?._id || useDataPoints.data[0]?._id}
              label={`${I18n('data_point', true)}:*`}
              stateName={`${stateName}.data_point`}
              onChange={onChange}
              data={useDataPoints.data.map(({ _id, name }) => ({ value: _id, name: name }))}
              placeholder={`${I18n('data_point', true)}`}
            />
          </FormCard>
        </Form>
      ) : (
        <Spin spinning={loading} />
      )}
      <div className="rk-button-group">
        <Save _id={_id} code={codeId} onCloseDrawer={onCloseDrawer} />
        {/* <State
              userId={userId}
              active={dataUserById.active}
              onCloseDrawer={() => onCloseDrawer()}
            /> */}
      </div>
    </>
  );
}
