import { Table as TableUI, Tag, Spin } from 'antd';
import { Container } from 'components';
import { GetPumpWaters } from 'apollo/configuration';
import { I18n } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Turn } from 'pages/motor-pumps/components';

const { Column } = TableUI;

export function Log() {
  const history = useHistory();
  const { data: datapump, loading, error } = GetPumpWaters();

  if (error) history.push('/error-server');

  if (loading) return <Spin spinning={loading} />;

  return (
    <Container>
      <TableUI dataSource={datapump} pagination={true} loading={loading} bordered>
        <Column title={I18n('code', true)} dataIndex={'code'} key={'code'} />
        <Column title={I18n('url', true)} dataIndex={'url'} key={'url'} />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <>
                {value ? (
                  <Tag
                    className="rk-tag"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    color={'success'}
                  >
                    {`  ${I18n('active')}`}
                  </Tag>
                ) : (
                  <Tag className="rk-tag" icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('inactive')}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
        <Column
          title={I18n('work', true)}
          dataIndex={'power_status'}
          key={'power_status'}
          render={(value) => {
            return (
              <>
                {value ? (
                  <Tag
                    className="rk-tag"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    color={'success'}
                  >
                    {`  ${I18n('on')}`}
                  </Tag>
                ) : (
                  <Tag className="rk-tag" icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('off')}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
        <Column
          title={I18n('action')}
          key={'action'}
          render={(action) => {
            return (
              <>
                <Turn _id={action._id} power_status={action.power_status} />
              </>
            );
          }}
        />
      </TableUI>
    </Container>
  );
}
