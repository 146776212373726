import { I18n } from 'i18n';
import { useState } from 'react';
import { Button } from 'antd';
import { Toast } from 'components';
import useComponents from 'hooks/useComponents';
import { pumpsConfigure } from 'apollo/configuration';

export function Save({ _id, onCloseDrawer }) {
  const [isLoading, setLoading] = useState(false);
  const {
    configuration: { waterpumps },
  } = useComponents();

  const handleClick = async () => {
    setLoading(true);
    const { error, message } = pumpsConfigure({ ...waterpumps, _id });
    if (error) {
      Toast.error({ key: 'error-save-water-pumps', message: I18n(message, true) });
    }
    if (!error) {
      onCloseDrawer();
      Toast.success({
        key: 'success-save-water-pumps',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...waterpumps, _id })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { _id, code, url } = variables;
  console.log(variables);
  if (_id) disabled = ![_id, code, url].every((v) => v);
  else disabled = ![code, url].every((v) => v);
  console.log(disabled);
  return disabled;
}
