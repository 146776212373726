import { useQuery, gql } from '@apollo/client';

export function GetWaterPumpById({ _id }) {
  const {
    data = { WaterPumpsById: {} },
    loading,
    error,
  } = useQuery(GET_WATERPUMPBYID, { variables: { _id } });
  return { isLoading: loading, data: data.WaterPumpsById || {}, error };
}

const GET_WATERPUMPBYID = gql`
  query WaterPumpsById($_id: ID!) {
    WaterPumpsById(_id: $_id) {
      _id
      code
      url
      customerId
      active
      power_status
    }
  }
`;
