import React from 'react';
import { I18n } from 'i18n';
import { Spin } from 'antd';
import { Container, Content } from 'components';
import { Table as TableUI } from 'antd';
import { Filters } from './filters';
import { useOverview } from 'apollo/filter-flowrates';
import { timeToMinutes } from 'utils';

const stateName = 'waterQuality.filterFlowrates.register';

export function Log() {
  const { data, loading } = useOverview();
  return (
    <Container>
      <Filters stateName={stateName} />
      <Content>
        {loading && !data.length ? (
          <Spin spinning={loading} />
        ) : (
          <TableUI
            dataSource={data}
            bordered
            scroll={{ y: 550 }}
            tableLayout="auto"
            size="small"
            pagination={false}
            rowKey={(data) => data._id + data.day + data.hours}
            columns={columns(data)}
          />
        )}
      </Content>
    </Container>
  );
}

function columns(data = []) {
  let columns = [];
  columns.push({
    title: I18n('hour', true),
    key: 'hours_apo',
    dataIndex: 'hours_apo',
    // filters: findFilters(data, 'hours_apo'),
    // onFilter: (value, record) => record['hours_apo'] === value,
    sorter: (a, b) => timeToMinutes(a.hours_apo) - timeToMinutes(b.hours_apo),
  });

  columns.push({
    title: I18n('flowrates', true),
    key: 'data_value',
    dataIndex: 'data_value',
    // filters: findFilters(data, 'data_value'),
    // onFilter: (value, record) => record['data_value'] === value,
    sorter: (a, b) => a.data_value - b.data_value,
  });

  return columns;
}

// function findFilters(data, key) {
//   let out = data.map((item) => item[key]);
//   out = [...new Set(out)].sort();
//   return out.map((value) => ({ text: value, value }));
// }
