import state from './state';
import account from './account';
import user from './user';
import customers from './customers';
import app from './app';
import modules from './modules';
import application from './application';
import roles from './roles';
import rolesByAccess from './rolesByAccess';
import Devices from './devices';
import DataPoint from './data-points';
import waterpumps from './waterpump';

const reducer = {
  state,
  account,
  user,
  customers,
  app,
  modules,
  application,
  roles,
  rolesByAccess,
  Devices,
  DataPoint,
  waterpumps,
};

export default reducer;
