import React from 'react';
import { I18n } from 'i18n';
import { componentsRedux } from 'redux/reusable';
import { Select } from 'components';
import PropTypes from 'prop-types';
import { days as daysFormat } from 'utils';
import { useResponseDates } from 'apollo/power';

const SingleDates = (props) => {
  const { year: selectedYear, week: SelectWeek, stateName } = props;
  const { data } = useResponseDates();
  let { Dates } = data || {};
  if (!Dates) return null;
  let years = Dates.reduce((memo, date) => {
      if (!memo.includes(date.year)) memo.push(date.year);
      return memo;
    }, [])
      .sort()
      .reverse()
      .map((value) => ({ value })),
    weeks = [],
    days = [];

  if (selectedYear) {
    weeks = Dates.reduce((memo, date) => {
      if (date.year !== selectedYear) return memo;
      if (!memo.includes(date.week)) memo.push(date.week);
      return memo;
    }, [])
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
      }, [])
      .reverse()
      .map((value) => ({ value }));
  }

  if (SelectWeek) {
    days = Dates.reduce((memo, date) => {
      if (date.week !== SelectWeek) return memo;
      if (!memo.includes(date.day)) memo.push(date.day);
      return memo;
    }, [])
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
      })
      .reverse()
      .map((value) => ({ value, name: daysFormat[value] }));
  }

  return (
    <>
      <Select
        key={`${stateName}.year`}
        label={I18n('year')}
        stateName={`${stateName}.year`}
        data={years}
        placeholder={I18n('year')}
        default={years[0]?.value}
        style={{ marginRight: 5 }}
      />
      <Select
        key={`${stateName}.week`}
        label={I18n('week')}
        stateName={`${stateName}.week`}
        data={weeks}
        placeholder={I18n('week')}
        default={weeks[0]?.value}
        style={{ marginRight: 5, width: 80 }}
      />
      <Select
        key={`${stateName}.day`}
        label={I18n('day')}
        stateName={`${stateName}.day`}
        data={days}
        placeholder={I18n('day')}
        default={days[0]?.value}
        style={{ width: 120 }}
      />
    </>
  );
};

SingleDates.propTypes = {
  stateName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default componentsRedux(SingleDates);
