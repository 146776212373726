import { useQuery, gql } from '@apollo/client';

export function useOverviewPower({ year, week, day, dataPoint }) {
  let variables = { week, year, day };
  const { data = { DataPowerLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });
  let dataOverview = [];
  data.DataPowerLog.forEach((rows) => {
    if (rows.data_point !== dataPoint) return;
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview };
}

function skip(variables) {
  let skip = true;
  let { year, week, day } = variables;
  skip = ![year, week, day].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ResponsePower($year: Int!, $week: Int!, $day: Int!) {
    DataPowerLog: ResponsePowerLog(year: $year, week: $week, day: $day) {
      _id
      year
      week
      day
      data_point
      voltage
      amperage
      hours_apo
    }
  }
`;
