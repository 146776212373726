import React from 'react';
import { useLocation } from 'react-router-dom';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Sider, Main } from './components';
import { ListUsers } from '../configuration/users';
import { ListDevices } from '../configuration/devices';
import { DataPoints, DataPointForm } from '../configuration/data-points';
import { PumpsList } from '../configuration/waterpump';

const ROUTES = [
  { key: 'users', name: I18n('users', true), path: '/configuration/users', component: ListUsers },
  {
    key: 'data-points',
    name: I18n('data_points', true),
    path: '/configuration/data-points',
    component: DataPoints,
  },
  {
    key: 'devices',
    name: I18n('devices', true),
    path: '/configuration/devices',
    component: ListDevices,
  },
  {
    key: 'waterpump',
    name: I18n('waterpumps', true),
    path: '/configuration/waterpumps',
    component: PumpsList,
  },
];

const FORMS = [
  { name: 'data_point', path: '/configuration/data-point/form', component: DataPointForm },
];

export default function Configuration() {
  const { collapseNav } = useSelector(({ app }) => app);
  const { pathname } = useLocation();
  const location = pathname.split('/');
  return (
    <Layout>
      {!collapseNav && (
        <Sider
          ROUTES={ROUTES}
          defaultSelzectedKeys={location[2]}
          title={I18n('configuration', true)}
          key={'configuration'}
        />
      )}
      <Main ROUTES={[...ROUTES, ...FORMS]} collapseNav={collapseNav} breadcrumb={location} />
    </Layout>
  );
}
